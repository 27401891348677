@import url('Fonts.css');

:root {
  --DARK_BLUE: rgb(15, 23, 42);
  --MED_BLUE: rgb(28, 45, 85);
  --HIGHLIGHT: rgb(57, 72, 107);

  --TEXT_BLUE: rgb(203,214,238);
  --TEXT_CYAN: rgb(94 234 212);
  --FONT_SIZE: 1rem;
  --H1_FONT_SIZE: 3rem;
  --H2_FONT_SIZE: 1.25rem;

  /* Overwrite bootstrap */
  --bs-accordion-bg: var(--DARK_BLUE);
}

body {
  background-color: var(--DARK_BLUE);
}

html {
  background-color: var(--DARK_BLUE);
}

h1 {
  font-size: var(--H1_FONT_SIZE);
  line-height: 1;
}

h2 {
  font-size: var(--H2_FONT_SIZE);
  line-height: 1.75rem;
  font-weight: 500;
}

p {
  font-size: var(--FONT_SIZE);
  line-height:1.5;
  
}

ul, ol {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;

  margin-top: .5rem;
}

ul li, ol li{
  padding-left: .75rem;
  padding-right: .75rem;
  padding-bottom: .25rem;

  margin-right: .5rem;

  background-color: rgba(45,212,191,.1);
  color: var(--TEXT_CYAN);
  border-radius: 9999px;
}

video {
  width: 100%;
}

#spotlight {
  position: absolute;
  background-color: transparent;
  border: 10px solid rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  pointer-events: none;
  transition: all 0.1s;
}

.App {
  background-color: var(--DARK_BLUE);
  font-family: 'NotoSansOsmanya-Regular', sans-serif;
  color: var(--TEXT_BLUE);
  padding: 1rem;
  font: var(--FONT_SIZE);
  padding: 0;
}

.App-header li {
  display: flex;
  flex-direction: column;
}

.experience {
  padding: .5rem;
  border-radius: .375rem;
  display: grid;
}

.experience:hover{
  background: var(--HIGHLIGHT);
}

.standard-margin-btm {
  margin-bottom: 16px;
}

.resume:hover span {
  border-bottom: 2px solid var(--TEXT_CYAN); 
}

.main-body a {
  text-decoration: none;
  color: var(--TEXT_CYAN);
}

.resume a {
  text-decoration: none;
  color: var(--TEXT_BLUE);
}

.image-list li {
  background-color: var(--DARK_BLUE);
}

.image-list li svg {
  color: var(--TEXT_BLUE);
  width: 3rem;
}

.image-list li svg:hover {
  color: var(--HIGHLIGHT);
}

.text-horizontal-list {
  margin: 0;
  padding: 0;
}

.text-horizontal-list li,
.text-horizontal-list li a {
  color: var(--TEXT_BLUE);
  background-color: var(--DARK_BLUE);

  padding-left: 0;
  padding-right: 0;

  border-radius: 0;
}

.text-horizontal-list li a:hover {
  border-bottom: 2px solid var(--TEXT_CYAN); 
}

.text-horizontal-list li:not(:last-child):after {
  content: '\00b7';
  margin-left: 10px;
}

.main-body {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.photo {
  height: 400px;
  margin: auto;
}

body .accordion, 
body .accordion-item,
body .accordion-button {
  background-color: var(--MED_BLUE);
  color: var(--TEXT_BLUE);
}

body .accordion-button.collapsed:hover {
  background: var(--HIGHLIGHT);
}

input {
  width: 20rem;
  background-color: var(--MED_BLUE);
  color: var(--TEXT_BLUE);
}

textarea {
  width: 20rem;
  background-color: var(--MED_BLUE);
  color: var(--TEXT_BLUE);
}

/* Desktop Only Styling */
@media (min-width: 768px) {
  .App-header {
    padding-top: 50%;
  }

  .Sticky {
    position: sticky;
    top: 0;
    z-index: 100;
  }

  .resume-padding {
    padding-top: 2rem;
  }

  .main-body {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  input {
    width: 20rem;
  }
  textarea {
    width: 40rem
  }
}
